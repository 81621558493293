let bladeList = [
    {
        "bladeId": 90,
        "manufacturer": "Adventure Technology",
        "model": "Geronimo",
        "size": "",
        "bladeArea": 72500,
        "bladeLength": 433,
        "bladeWidth": 190,
        "tipToCoP": "",
        "kickbackAngle": "",
        "equivLength": 406,
        "equivWidth": 178
    },
    {
        "bladeId": 89,
        "manufacturer": "Adventure Technology",
        "model": "Hercules",
        "size": "",
        "bladeArea": 70000,
        "bladeLength": 431,
        "bladeWidth": 195,
        "tipToCoP": "",
        "kickbackAngle": "",
        "equivLength": 393,
        "equivWidth": 178
    },
    {
        "bladeId": 91,
        "manufacturer": "Adventure Technology",
        "model": "Samurai",
        "size": "",
        "bladeArea": 67500,
        "bladeLength": 412,
        "bladeWidth": 200,
        "tipToCoP": "",
        "kickbackAngle": "",
        "equivLength": 373,
        "equivWidth": 181
    },
    {
        "bladeId": 5,
        "manufacturer": "Braca",
        "model": "II",
        "size": "s-max 820",
        "bladeArea": 82000,
        "bladeLength": 518,
        "bladeWidth": 172,
        "tipToCoP": 242,
        "kickbackAngle": "",
        "equivLength": 497,
        "equivWidth": 165
    },
    {
        "bladeId": 114,
        "manufacturer": "Braca",
        "model": "Rocky 2",
        "size": "max",
        "bladeArea": 83500,
        "bladeLength": 500,
        "bladeWidth": 206,
        "tipToCoP": 214,
        "kickbackAngle": 0,
        "equivLength": 450,
        "equivWidth": 185
    },
    {
        "bladeId": 104,
        "manufacturer": "Braca",
        "model": "Uni Extra Wide",
        "size": "50x22",
        "bladeArea": 94600,
        "bladeLength": 490,
        "bladeWidth": 220,
        "tipToCoP": 223,
        "kickbackAngle": 0,
        "equivLength": 459,
        "equivWidth": 206
    },
    {
        "bladeId": 113,
        "manufacturer": "Braca",
        "model": "Uni Super Fat",
        "size": "50x23.5",
        "bladeArea": 101000,
        "bladeLength": 490,
        "bladeWidth": 235,
        "tipToCoP": 218,
        "kickbackAngle": 0,
        "equivLength": 459,
        "equivWidth": 220
    },
    {
        "bladeId": 1,
        "manufacturer": "Brača",
        "model": "I",
        "size": "max 840",
        "bladeArea": 84000,
        "bladeLength": 520,
        "bladeWidth": 175,
        "tipToCoP": 243,
        "kickbackAngle": "",
        "equivLength": 500,
        "equivWidth": 168
    },
    {
        "bladeId": 123,
        "manufacturer": "Brača",
        "model": "I",
        "size": "s-max 820",
        "bladeArea": 82000,
        "bladeLength": 518,
        "bladeWidth": 172,
        "tipToCoP": 242,
        "kickbackAngle": "",
        "equivLength": 497,
        "equivWidth": 165
    },
    {
        "bladeId": 2,
        "manufacturer": "Brača",
        "model": "I",
        "size": "min 805",
        "bladeArea": 80500,
        "bladeLength": 516,
        "bladeWidth": 170,
        "tipToCoP": 241,
        "kickbackAngle": "",
        "equivLength": 494,
        "equivWidth": 163
    },
    {
        "bladeId": 3,
        "manufacturer": "Brača",
        "model": "I",
        "size": "s-min 790",
        "bladeArea": 79000,
        "bladeLength": 514,
        "bladeWidth": 169,
        "tipToCoP": 240,
        "kickbackAngle": "",
        "equivLength": 490,
        "equivWidth": 161
    },
    {
        "bladeId": 163,
        "manufacturer": "Brača",
        "model": "I",
        "size": 775,
        "bladeArea": 77500,
        "bladeLength": 512,
        "bladeWidth": 165,
        "tipToCoP": 239,
        "kickbackAngle": "",
        "equivLength": 490,
        "equivWidth": 158
    },
    {
        "bladeId": 4,
        "manufacturer": "Brača",
        "model": "II",
        "size": "max 835",
        "bladeArea": 83500,
        "bladeLength": 520,
        "bladeWidth": 175,
        "tipToCoP": 243,
        "kickbackAngle": "",
        "equivLength": 498,
        "equivWidth": 168
    },
    {
        "bladeId": 6,
        "manufacturer": "Brača",
        "model": "II",
        "size": "min 815",
        "bladeArea": 81500,
        "bladeLength": 516,
        "bladeWidth": 170,
        "tipToCoP": 241,
        "kickbackAngle": "",
        "equivLength": 497,
        "equivWidth": 164
    },
    {
        "bladeId": 124,
        "manufacturer": "Brača",
        "model": "II",
        "size": 805,
        "bladeArea": 80500,
        "bladeLength": 515,
        "bladeWidth": 169,
        "tipToCoP": 241,
        "kickbackAngle": "",
        "equivLength": 495,
        "equivWidth": 163
    },
    {
        "bladeId": 7,
        "manufacturer": "Brača",
        "model": "II",
        "size": "s-min 790",
        "bladeArea": 79000,
        "bladeLength": 514,
        "bladeWidth": 168,
        "tipToCoP": 240,
        "kickbackAngle": "",
        "equivLength": 492,
        "equivWidth": 161
    },
    {
        "bladeId": 164,
        "manufacturer": "Brača",
        "model": "II ",
        "size": "s-max 820",
        "bladeArea": 82000,
        "bladeLength": 518,
        "bladeWidth": 172,
        "tipToCoP": 242,
        "kickbackAngle": "",
        "equivLength": 497,
        "equivWidth": 165
    },
    {
        "bladeId": 8,
        "manufacturer": "Brača",
        "model": "IV",
        "size": "max 765",
        "bladeArea": 76500,
        "bladeLength": 500,
        "bladeWidth": 168,
        "tipToCoP": 235,
        "kickbackAngle": "",
        "equivLength": 477,
        "equivWidth": 160
    },
    {
        "bladeId": 165,
        "manufacturer": "Brača",
        "model": "IV",
        "size": 750,
        "bladeArea": 75000,
        "bladeLength": 498,
        "bladeWidth": 165,
        "tipToCoP": 234,
        "kickbackAngle": "",
        "equivLength": 476,
        "equivWidth": 158
    },
    {
        "bladeId": 9,
        "manufacturer": "Brača",
        "model": "IV",
        "size": "min 735",
        "bladeArea": 73500,
        "bladeLength": 495,
        "bladeWidth": 163,
        "tipToCoP": 233,
        "kickbackAngle": "",
        "equivLength": 472,
        "equivWidth": 156
    },
    {
        "bladeId": 166,
        "manufacturer": "Brača",
        "model": "IV",
        "size": 720,
        "bladeArea": 72000,
        "bladeLength": 493,
        "bladeWidth": 160,
        "tipToCoP": 232,
        "kickbackAngle": "",
        "equivLength": 471,
        "equivWidth": 153
    },
    {
        "bladeId": 10,
        "manufacturer": "Brača",
        "model": "IV",
        "size": "s-min 705",
        "bladeArea": 70500,
        "bladeLength": 490,
        "bladeWidth": 158,
        "tipToCoP": 230,
        "kickbackAngle": "",
        "equivLength": 468,
        "equivWidth": 151
    },
    {
        "bladeId": 167,
        "manufacturer": "Brača",
        "model": "IV",
        "size": 670,
        "bladeArea": 67000,
        "bladeLength": 475,
        "bladeWidth": 152,
        "tipToCoP": 223,
        "kickbackAngle": "",
        "equivLength": 458,
        "equivWidth": 146
    },
    {
        "bladeId": 168,
        "manufacturer": "Brača",
        "model": "IV",
        "size": "xs-min 635",
        "bladeArea": 63500,
        "bladeLength": 453,
        "bladeWidth": 151,
        "tipToCoP": 213,
        "kickbackAngle": "",
        "equivLength": 436,
        "equivWidth": 145
    },
    {
        "bladeId": 23,
        "manufacturer": "Brača",
        "model": "IX",
        "size": "x-max 800",
        "bladeArea": 80000,
        "bladeLength": 522,
        "bladeWidth": 162,
        "tipToCoP": 242,
        "kickbackAngle": "",
        "equivLength": 508,
        "equivWidth": 158
    },
    {
        "bladeId": 24,
        "manufacturer": "Brača",
        "model": "IX",
        "size": "max 765",
        "bladeArea": 76500,
        "bladeLength": 518,
        "bladeWidth": 160,
        "tipToCoP": 240,
        "kickbackAngle": "",
        "equivLength": 498,
        "equivWidth": 154
    },
    {
        "bladeId": 25,
        "manufacturer": "Brača",
        "model": "IX",
        "size": "min 740",
        "bladeArea": 74000,
        "bladeLength": 515,
        "bladeWidth": 158,
        "tipToCoP": 238,
        "kickbackAngle": "",
        "equivLength": 491,
        "equivWidth": 151
    },
    {
        "bladeId": 11,
        "manufacturer": "Brača",
        "model": "V",
        "size": 810,
        "bladeArea": 81000,
        "bladeLength": 520,
        "bladeWidth": 171,
        "tipToCoP": 248,
        "kickbackAngle": "",
        "equivLength": 496,
        "equivWidth": 163
    },
    {
        "bladeId": 12,
        "manufacturer": "Brača",
        "model": "V DW",
        "size": "max 860",
        "bladeArea": 86000,
        "bladeLength": 530,
        "bladeWidth": 170,
        "tipToCoP": 252,
        "kickbackAngle": "",
        "equivLength": 518,
        "equivWidth": 166
    },
    {
        "bladeId": 13,
        "manufacturer": "Brača",
        "model": "V DW",
        "size": 825,
        "bladeArea": 82500,
        "bladeLength": 528,
        "bladeWidth": 166,
        "tipToCoP": 251,
        "kickbackAngle": "",
        "equivLength": 512,
        "equivWidth": 161
    },
    {
        "bladeId": 14,
        "manufacturer": "Brača",
        "model": "V DW",
        "size": 805,
        "bladeArea": 74000,
        "bladeLength": 526,
        "bladeWidth": 164,
        "tipToCoP": 250,
        "kickbackAngle": "",
        "equivLength": 487,
        "equivWidth": 152
    },
    {
        "bladeId": 15,
        "manufacturer": "Brača",
        "model": "VI",
        "size": "max 780",
        "bladeArea": 78000,
        "bladeLength": 520,
        "bladeWidth": 175,
        "tipToCoP": 241,
        "kickbackAngle": "",
        "equivLength": 481,
        "equivWidth": 162
    },
    {
        "bladeId": 16,
        "manufacturer": "Brača",
        "model": "VI",
        "size": "min 765",
        "bladeArea": 76500,
        "bladeLength": 517,
        "bladeWidth": 171,
        "tipToCoP": 240,
        "kickbackAngle": "",
        "equivLength": 481,
        "equivWidth": 159
    },
    {
        "bladeId": 17,
        "manufacturer": "Brača",
        "model": "VI",
        "size": "s-min 750",
        "bladeArea": 75000,
        "bladeLength": 515,
        "bladeWidth": 170,
        "tipToCoP": 239,
        "kickbackAngle": "",
        "equivLength": 477,
        "equivWidth": 157
    },
    {
        "bladeId": 18,
        "manufacturer": "Brača",
        "model": "VII",
        "size": "max 745",
        "bladeArea": 74500,
        "bladeLength": 493,
        "bladeWidth": 167,
        "tipToCoP": 229,
        "kickbackAngle": "",
        "equivLength": 469,
        "equivWidth": 159
    },
    {
        "bladeId": 19,
        "manufacturer": "Brača",
        "model": "VII",
        "size": "min 725",
        "bladeArea": 72500,
        "bladeLength": 489,
        "bladeWidth": 164,
        "tipToCoP": 227,
        "kickbackAngle": "",
        "equivLength": 465,
        "equivWidth": 156
    },
    {
        "bladeId": 169,
        "manufacturer": "Brača",
        "model": "VII",
        "size": "s-min 710",
        "bladeArea": 71000,
        "bladeLength": 487,
        "bladeWidth": 162,
        "tipToCoP": 226,
        "kickbackAngle": "",
        "equivLength": 462,
        "equivWidth": 154
    },
    {
        "bladeId": 20,
        "manufacturer": "Brača",
        "model": "VIII",
        "size": "x-max 837",
        "bladeArea": 83700,
        "bladeLength": 575,
        "bladeWidth": 155,
        "tipToCoP": 266,
        "kickbackAngle": "",
        "equivLength": 557,
        "equivWidth": 150
    },
    {
        "bladeId": 21,
        "manufacturer": "Brača",
        "model": "VIII",
        "size": "max 799",
        "bladeArea": 79900,
        "bladeLength": 574,
        "bladeWidth": 150,
        "tipToCoP": 266,
        "kickbackAngle": "",
        "equivLength": 553,
        "equivWidth": 144
    },
    {
        "bladeId": 22,
        "manufacturer": "Brača",
        "model": "VIII",
        "size": "min 767",
        "bladeArea": 76700,
        "bladeLength": 573,
        "bladeWidth": 146,
        "tipToCoP": 265,
        "kickbackAngle": "",
        "equivLength": 549,
        "equivWidth": 140
    },
    {
        "bladeId": 26,
        "manufacturer": "Brača",
        "model": "X",
        "size": "max 705",
        "bladeArea": 70500,
        "bladeLength": 500,
        "bladeWidth": 153,
        "tipToCoP": 232,
        "kickbackAngle": "",
        "equivLength": 480,
        "equivWidth": 147
    },
    {
        "bladeId": 27,
        "manufacturer": "Brača",
        "model": "X",
        "size": "min 680",
        "bladeArea": 68000,
        "bladeLength": 495,
        "bladeWidth": 150,
        "tipToCoP": 229,
        "kickbackAngle": "",
        "equivLength": 474,
        "equivWidth": 144
    },
    {
        "bladeId": 28,
        "manufacturer": "Brača",
        "model": "XI",
        "size": "x-max 835",
        "bladeArea": 83500,
        "bladeLength": 519,
        "bladeWidth": 181,
        "tipToCoP": 235,
        "kickbackAngle": "",
        "equivLength": 489,
        "equivWidth": 171
    },
    {
        "bladeId": 29,
        "manufacturer": "Brača",
        "model": "XI",
        "size": "max 815",
        "bladeArea": 81500,
        "bladeLength": 516,
        "bladeWidth": 176,
        "tipToCoP": 234,
        "kickbackAngle": "",
        "equivLength": 489,
        "equivWidth": 167
    },
    {
        "bladeId": 125,
        "manufacturer": "Brača",
        "model": "XI",
        "size": 805,
        "bladeArea": 80500,
        "bladeLength": 514,
        "bladeWidth": 174,
        "tipToCoP": 233,
        "kickbackAngle": "",
        "equivLength": 488,
        "equivWidth": 165
    },
    {
        "bladeId": 30,
        "manufacturer": "Brača",
        "model": "XI",
        "size": "s-max 795",
        "bladeArea": 79500,
        "bladeLength": 513,
        "bladeWidth": 172,
        "tipToCoP": 232,
        "kickbackAngle": "",
        "equivLength": 487,
        "equivWidth": 163
    },
    {
        "bladeId": 31,
        "manufacturer": "Brača",
        "model": "XI",
        "size": "x-min 775",
        "bladeArea": 77500,
        "bladeLength": 511,
        "bladeWidth": 168,
        "tipToCoP": 231,
        "kickbackAngle": "",
        "equivLength": 486,
        "equivWidth": 160
    },
    {
        "bladeId": 32,
        "manufacturer": "Brača",
        "model": "XI",
        "size": "min 760",
        "bladeArea": 76000,
        "bladeLength": 511,
        "bladeWidth": 160,
        "tipToCoP": 231,
        "kickbackAngle": "",
        "equivLength": 493,
        "equivWidth": 154
    },
    {
        "bladeId": 33,
        "manufacturer": "Brača",
        "model": "XI",
        "size": "s-min 735",
        "bladeArea": 73500,
        "bladeLength": 505,
        "bladeWidth": 157,
        "tipToCoP": 229,
        "kickbackAngle": "",
        "equivLength": 486,
        "equivWidth": 151
    },
    {
        "bladeId": 126,
        "manufacturer": "Brača",
        "model": "XI",
        "size": 720,
        "bladeArea": 72000,
        "bladeLength": 501,
        "bladeWidth": 156,
        "tipToCoP": 227,
        "kickbackAngle": "",
        "equivLength": 481,
        "equivWidth": 150
    },
    {
        "bladeId": 34,
        "manufacturer": "Brača",
        "model": "XI",
        "size": "xs-min 705",
        "bladeArea": 70500,
        "bladeLength": 498,
        "bladeWidth": 155,
        "tipToCoP": 226,
        "kickbackAngle": "",
        "equivLength": 476,
        "equivWidth": 148
    },
    {
        "bladeId": 98,
        "manufacturer": "Double Dutch",
        "model": "Dynamic",
        "size": "Large",
        "bladeArea": 75500,
        "bladeLength": 480,
        "bladeWidth": 207,
        "tipToCoP": "",
        "kickbackAngle": "",
        "equivLength": 418,
        "equivWidth": 180
    },
    {
        "bladeId": 97,
        "manufacturer": "Double Dutch",
        "model": "Dynamic",
        "size": "Medium",
        "bladeArea": 71000,
        "bladeLength": 470,
        "bladeWidth": 200,
        "tipToCoP": "",
        "kickbackAngle": "",
        "equivLength": 408,
        "equivWidth": 174
    },
    {
        "bladeId": 119,
        "manufacturer": "Enevya",
        "model": "TKS-2",
        "size": "TKS-2",
        "bladeArea": 72500,
        "bladeLength": 490,
        "bladeWidth": 210,
        "tipToCoP": 209,
        "kickbackAngle": "",
        "equivLength": 411,
        "equivWidth": 176
    },
    {
        "bladeId": 161,
        "manufacturer": "Flow",
        "model": "MS1",
        "size": "MS1",
        "bladeArea": 62000,
        "bladeLength": 457,
        "bladeWidth": 151,
        "tipToCoP": 210,
        "kickbackAngle": "",
        "equivLength": 433,
        "equivWidth": 143
    },
    {
        "bladeId": 35,
        "manufacturer": "Flow",
        "model": "MS2",
        "size": "MS2",
        "bladeArea": 66000,
        "bladeLength": 477,
        "bladeWidth": 157,
        "tipToCoP": 219,
        "kickbackAngle": "",
        "equivLength": 448,
        "equivWidth": 147
    },
    {
        "bladeId": 162,
        "manufacturer": "Flow",
        "model": "MS3",
        "size": "MS3",
        "bladeArea": 71000,
        "bladeLength": 497,
        "bladeWidth": 164,
        "tipToCoP": 228,
        "kickbackAngle": "",
        "equivLength": 464,
        "equivWidth": 153
    },
    {
        "bladeId": 43,
        "manufacturer": "Galasport",
        "model": "Meg",
        "size": "XL",
        "bladeArea": 80000,
        "bladeLength": 485,
        "bladeWidth": 205,
        "tipToCoP": 224,
        "kickbackAngle": "",
        "equivLength": 435,
        "equivWidth": 184
    },
    {
        "bladeId": 42,
        "manufacturer": "Galasport",
        "model": "Meg",
        "size": "L",
        "bladeArea": 76000,
        "bladeLength": 475,
        "bladeWidth": 203,
        "tipToCoP": 219,
        "kickbackAngle": "",
        "equivLength": 422,
        "equivWidth": 180
    },
    {
        "bladeId": 118,
        "manufacturer": "Galasport",
        "model": "Meg",
        "size": "Maxi",
        "bladeArea": 73500,
        "bladeLength": 485,
        "bladeWidth": 213,
        "tipToCoP": 224,
        "kickbackAngle": "",
        "equivLength": 409,
        "equivWidth": 180
    },
    {
        "bladeId": 41,
        "manufacturer": "Galasport",
        "model": "Meg",
        "size": "M",
        "bladeArea": 70000,
        "bladeLength": 470,
        "bladeWidth": 193,
        "tipToCoP": 217,
        "kickbackAngle": "",
        "equivLength": 413,
        "equivWidth": 170
    },
    {
        "bladeId": 117,
        "manufacturer": "Galasport",
        "model": "Meg",
        "size": "Midi",
        "bladeArea": 66300,
        "bladeLength": 475,
        "bladeWidth": 205,
        "tipToCoP": 219,
        "kickbackAngle": "",
        "equivLength": 392,
        "equivWidth": 169
    },
    {
        "bladeId": 116,
        "manufacturer": "Galasport",
        "model": "Meg",
        "size": "S",
        "bladeArea": 62000,
        "bladeLength": 460,
        "bladeWidth": 188,
        "tipToCoP": 213,
        "kickbackAngle": "",
        "equivLength": 389,
        "equivWidth": 159
    },
    {
        "bladeId": 40,
        "manufacturer": "Galasport",
        "model": "Naja",
        "size": "Maxi",
        "bladeArea": 71200,
        "bladeLength": 500,
        "bladeWidth": 207,
        "tipToCoP": 218,
        "kickbackAngle": "",
        "equivLength": 415,
        "equivWidth": 172
    },
    {
        "bladeId": 39,
        "manufacturer": "Galasport",
        "model": "Naja",
        "size": "Midi",
        "bladeArea": 69000,
        "bladeLength": 490,
        "bladeWidth": 202,
        "tipToCoP": 213,
        "kickbackAngle": "",
        "equivLength": 409,
        "equivWidth": 169
    },
    {
        "bladeId": 38,
        "manufacturer": "Galasport",
        "model": "Naja",
        "size": "Mini",
        "bladeArea": 65800,
        "bladeLength": 490,
        "bladeWidth": 193,
        "tipToCoP": 213,
        "kickbackAngle": "",
        "equivLength": 409,
        "equivWidth": 161
    },
    {
        "bladeId": 115,
        "manufacturer": "Galasport",
        "model": "Phil C1",
        "size": "",
        "bladeArea": 78900,
        "bladeLength": 505,
        "bladeWidth": 194,
        "tipToCoP": 216,
        "kickbackAngle": 0,
        "equivLength": 453,
        "equivWidth": 174
    },
    {
        "bladeId": 44,
        "manufacturer": "Gara",
        "model": "GP2",
        "size": "GP2",
        "bladeArea": 74000,
        "bladeLength": 490,
        "bladeWidth": 165,
        "tipToCoP": "",
        "kickbackAngle": "",
        "equivLength": 469,
        "equivWidth": 158
    },
    {
        "bladeId": 100,
        "manufacturer": "Gpower",
        "model": "Challenger",
        "size": "Medium",
        "bladeArea": 77000,
        "bladeLength": 508,
        "bladeWidth": 188,
        "tipToCoP": 218,
        "kickbackAngle": 0,
        "equivLength": 456,
        "equivWidth": 169
    },
    {
        "bladeId": 111,
        "manufacturer": "Gpower",
        "model": "Revolution ",
        "size": "Small",
        "bladeArea": 79700,
        "bladeLength": 490,
        "bladeWidth": 197,
        "tipToCoP": 211,
        "kickbackAngle": 0,
        "equivLength": 445,
        "equivWidth": 179
    },
    {
        "bladeId": 112,
        "manufacturer": "Gpower",
        "model": "Revolution ",
        "size": "Large",
        "bladeArea": 79700,
        "bladeLength": 505,
        "bladeWidth": 205,
        "tipToCoP": 217,
        "kickbackAngle": 0,
        "equivLength": 443,
        "equivWidth": 180
    },
    {
        "bladeId": 101,
        "manufacturer": "Gpower",
        "model": "Revolution ",
        "size": "Medium",
        "bladeArea": 78500,
        "bladeLength": 505,
        "bladeWidth": 200,
        "tipToCoP": 217,
        "kickbackAngle": 0,
        "equivLength": 445,
        "equivWidth": 176
    },
    {
        "bladeId": 36,
        "manufacturer": "G'Power",
        "model": "Hunter",
        "size": "XL",
        "bladeArea": 76000,
        "bladeLength": 465,
        "bladeWidth": 221,
        "tipToCoP": 211,
        "kickbackAngle": "",
        "equivLength": 400,
        "equivWidth": 190
    },
    {
        "bladeId": 110,
        "manufacturer": "G'Power",
        "model": "Hunter",
        "size": "Medium",
        "bladeArea": 71500,
        "bladeLength": 455,
        "bladeWidth": 211,
        "tipToCoP": 207,
        "kickbackAngle": "",
        "equivLength": 393,
        "equivWidth": 182
    },
    {
        "bladeId": 37,
        "manufacturer": "G'Power",
        "model": "Spider",
        "size": "L",
        "bladeArea": 75200,
        "bladeLength": 490,
        "bladeWidth": 215,
        "tipToCoP": "",
        "kickbackAngle": "",
        "equivLength": 414,
        "equivWidth": 182
    },
    {
        "bladeId": 45,
        "manufacturer": "Gut",
        "model": "Gut",
        "size": "LM",
        "bladeArea": 83800,
        "bladeLength": 522,
        "bladeWidth": 173,
        "tipToCoP": 247,
        "kickbackAngle": "",
        "equivLength": 503,
        "equivWidth": 167
    },
    {
        "bladeId": 46,
        "manufacturer": "Gut",
        "model": "Gut",
        "size": "M",
        "bladeArea": 81700,
        "bladeLength": 515,
        "bladeWidth": 171,
        "tipToCoP": 243,
        "kickbackAngle": "",
        "equivLength": 496,
        "equivWidth": 165
    },
    {
        "bladeId": 105,
        "manufacturer": "Gut",
        "model": "Gut",
        "size": "S",
        "bladeArea": 76100,
        "bladeLength": 500,
        "bladeWidth": 164,
        "tipToCoP": 236,
        "kickbackAngle": "",
        "equivLength": 482,
        "equivWidth": 158
    },
    {
        "bladeId": 106,
        "manufacturer": "Gut",
        "model": "Gut",
        "size": "XS",
        "bladeArea": 73200,
        "bladeLength": 490,
        "bladeWidth": 161,
        "tipToCoP": 232,
        "kickbackAngle": "",
        "equivLength": 472,
        "equivWidth": 155
    },
    {
        "bladeId": 131,
        "manufacturer": "IDBF",
        "model": "Dragonboat",
        "size": "IDBF standard",
        "bladeArea": 69700,
        "bladeLength": 480,
        "bladeWidth": 180,
        "tipToCoP": 212,
        "kickbackAngle": "",
        "equivLength": 431,
        "equivWidth": 162
    },
    {
        "bladeId": 47,
        "manufacturer": "Jantex",
        "model": "Beta",
        "size": "Large Plus",
        "bladeArea": 84500,
        "bladeLength": 525,
        "bladeWidth": 172,
        "tipToCoP": 248,
        "kickbackAngle": "",
        "equivLength": 508,
        "equivWidth": 166
    },
    {
        "bladeId": 148,
        "manufacturer": "Jantex",
        "model": "Beta",
        "size": 840,
        "bladeArea": 84000,
        "bladeLength": 515,
        "bladeWidth": 178,
        "tipToCoP": 240,
        "kickbackAngle": "",
        "equivLength": 493,
        "equivWidth": 170
    },
    {
        "bladeId": 48,
        "manufacturer": "Jantex",
        "model": "Beta",
        "size": "Large ",
        "bladeArea": 82000,
        "bladeLength": 520,
        "bladeWidth": 170,
        "tipToCoP": 245,
        "kickbackAngle": "",
        "equivLength": 501,
        "equivWidth": 164
    },
    {
        "bladeId": 149,
        "manufacturer": "Jantex",
        "model": "Beta",
        "size": 820,
        "bladeArea": 82000,
        "bladeLength": 510,
        "bladeWidth": 178,
        "tipToCoP": 238,
        "kickbackAngle": "",
        "equivLength": 485,
        "equivWidth": 169
    },
    {
        "bladeId": 150,
        "manufacturer": "Jantex",
        "model": "Beta",
        "size": 810,
        "bladeArea": 81000,
        "bladeLength": 507,
        "bladeWidth": 176,
        "tipToCoP": 236,
        "kickbackAngle": "",
        "equivLength": 483,
        "equivWidth": 168
    },
    {
        "bladeId": 49,
        "manufacturer": "Jantex",
        "model": "Beta",
        "size": "Large Minus",
        "bladeArea": 80000,
        "bladeLength": 515,
        "bladeWidth": 168,
        "tipToCoP": 243,
        "kickbackAngle": "",
        "equivLength": 495,
        "equivWidth": 162
    },
    {
        "bladeId": 151,
        "manufacturer": "Jantex",
        "model": "Beta",
        "size": 800,
        "bladeArea": 80000,
        "bladeLength": 505,
        "bladeWidth": 176,
        "tipToCoP": 235,
        "kickbackAngle": "",
        "equivLength": 479,
        "equivWidth": 167
    },
    {
        "bladeId": 152,
        "manufacturer": "Jantex",
        "model": "Beta",
        "size": 790,
        "bladeArea": 79000,
        "bladeLength": 503,
        "bladeWidth": 168,
        "tipToCoP": 234,
        "kickbackAngle": "",
        "equivLength": 486,
        "equivWidth": 162
    },
    {
        "bladeId": 50,
        "manufacturer": "Jantex",
        "model": "Beta",
        "size": "Medium Plus",
        "bladeArea": 78000,
        "bladeLength": 510,
        "bladeWidth": 166,
        "tipToCoP": 241,
        "kickbackAngle": "",
        "equivLength": 490,
        "equivWidth": 159
    },
    {
        "bladeId": 153,
        "manufacturer": "Jantex",
        "model": "Beta",
        "size": 780,
        "bladeArea": 78000,
        "bladeLength": 500,
        "bladeWidth": 166,
        "tipToCoP": 233,
        "kickbackAngle": "",
        "equivLength": 485,
        "equivWidth": 161
    },
    {
        "bladeId": 154,
        "manufacturer": "Jantex",
        "model": "Beta",
        "size": 760,
        "bladeArea": 76000,
        "bladeLength": 495,
        "bladeWidth": 166,
        "tipToCoP": 231,
        "kickbackAngle": "",
        "equivLength": 476,
        "equivWidth": 160
    },
    {
        "bladeId": 51,
        "manufacturer": "Jantex",
        "model": "Beta",
        "size": "Medium ",
        "bladeArea": 75000,
        "bladeLength": 500,
        "bladeWidth": 164,
        "tipToCoP": 236,
        "kickbackAngle": "",
        "equivLength": 478,
        "equivWidth": 157
    },
    {
        "bladeId": 155,
        "manufacturer": "Jantex",
        "model": "Beta",
        "size": 740,
        "bladeArea": 74000,
        "bladeLength": 490,
        "bladeWidth": 166,
        "tipToCoP": 228,
        "kickbackAngle": "",
        "equivLength": 467,
        "equivWidth": 158
    },
    {
        "bladeId": 156,
        "manufacturer": "Jantex",
        "model": "Beta",
        "size": 720,
        "bladeArea": 72000,
        "bladeLength": 485,
        "bladeWidth": 158,
        "tipToCoP": 226,
        "kickbackAngle": "",
        "equivLength": 470,
        "equivWidth": 153
    },
    {
        "bladeId": 157,
        "manufacturer": "Jantex",
        "model": "Beta",
        "size": 700,
        "bladeArea": 70000,
        "bladeLength": 480,
        "bladeWidth": 158,
        "tipToCoP": 224,
        "kickbackAngle": "",
        "equivLength": 461,
        "equivWidth": 152
    },
    {
        "bladeId": 158,
        "manufacturer": "Jantex",
        "model": "Beta",
        "size": 680,
        "bladeArea": 68000,
        "bladeLength": 475,
        "bladeWidth": 158,
        "tipToCoP": 221,
        "kickbackAngle": "",
        "equivLength": 452,
        "equivWidth": 150
    },
    {
        "bladeId": 159,
        "manufacturer": "Jantex",
        "model": "Beta",
        "size": 660,
        "bladeArea": 66000,
        "bladeLength": 470,
        "bladeWidth": 155,
        "tipToCoP": 219,
        "kickbackAngle": "",
        "equivLength": 447,
        "equivWidth": 148
    },
    {
        "bladeId": 160,
        "manufacturer": "Jantex",
        "model": "Beta",
        "size": 640,
        "bladeArea": 64000,
        "bladeLength": 465,
        "bladeWidth": 155,
        "tipToCoP": 217,
        "kickbackAngle": "",
        "equivLength": 438,
        "equivWidth": 146
    },
    {
        "bladeId": 52,
        "manufacturer": "Jantex",
        "model": "Beta Rio",
        "size": "Large Plus",
        "bladeArea": 84000,
        "bladeLength": 515,
        "bladeWidth": 178,
        "tipToCoP": 240,
        "kickbackAngle": "",
        "equivLength": 493,
        "equivWidth": 170
    },
    {
        "bladeId": 53,
        "manufacturer": "Jantex",
        "model": "Beta Rio",
        "size": "Large",
        "bladeArea": 82000,
        "bladeLength": 510,
        "bladeWidth": 178,
        "tipToCoP": 238,
        "kickbackAngle": "",
        "equivLength": 485,
        "equivWidth": 169
    },
    {
        "bladeId": 54,
        "manufacturer": "Jantex",
        "model": "Beta Rio",
        "size": "Large Minus",
        "bladeArea": 80000,
        "bladeLength": 505,
        "bladeWidth": 178,
        "tipToCoP": 235,
        "kickbackAngle": "",
        "equivLength": 476,
        "equivWidth": 168
    },
    {
        "bladeId": 55,
        "manufacturer": "Jantex",
        "model": "Beta Rio",
        "size": "Medium Plus",
        "bladeArea": 78000,
        "bladeLength": 500,
        "bladeWidth": 166,
        "tipToCoP": 233,
        "kickbackAngle": "",
        "equivLength": 485,
        "equivWidth": 161
    },
    {
        "bladeId": 56,
        "manufacturer": "Jantex",
        "model": "Beta Rio",
        "size": "Medium",
        "bladeArea": 76000,
        "bladeLength": 495,
        "bladeWidth": 166,
        "tipToCoP": 231,
        "kickbackAngle": "",
        "equivLength": 476,
        "equivWidth": 160
    },
    {
        "bladeId": 57,
        "manufacturer": "Jantex",
        "model": "Beta Rio",
        "size": "Medium Minus",
        "bladeArea": 74000,
        "bladeLength": 490,
        "bladeWidth": 166,
        "tipToCoP": 228,
        "kickbackAngle": "",
        "equivLength": 467,
        "equivWidth": 158
    },
    {
        "bladeId": 109,
        "manufacturer": "Jantex",
        "model": "Beta Rio",
        "size": "X Small Plus",
        "bladeArea": 73000,
        "bladeLength": 485,
        "bladeWidth": 160,
        "tipToCoP": 226,
        "kickbackAngle": "",
        "equivLength": 470,
        "equivWidth": 155
    },
    {
        "bladeId": 58,
        "manufacturer": "Jantex",
        "model": "Beta Rio",
        "size": "Small Plus",
        "bladeArea": 72000,
        "bladeLength": 488,
        "bladeWidth": 158,
        "tipToCoP": 227,
        "kickbackAngle": "",
        "equivLength": 472,
        "equivWidth": 153
    },
    {
        "bladeId": 59,
        "manufacturer": "Jantex",
        "model": "Beta Rio",
        "size": "Small",
        "bladeArea": 70000,
        "bladeLength": 480,
        "bladeWidth": 158,
        "tipToCoP": 224,
        "kickbackAngle": "",
        "equivLength": 461,
        "equivWidth": 152
    },
    {
        "bladeId": 60,
        "manufacturer": "Jantex",
        "model": "Gamma",
        "size": "Large Plus",
        "bladeArea": 84500,
        "bladeLength": 515,
        "bladeWidth": 180,
        "tipToCoP": 234,
        "kickbackAngle": "",
        "equivLength": 492,
        "equivWidth": 172
    },
    {
        "bladeId": 134,
        "manufacturer": "Jantex",
        "model": "Gamma",
        "size": 840,
        "bladeArea": 84000,
        "bladeLength": 515,
        "bladeWidth": 178,
        "tipToCoP": 236,
        "kickbackAngle": "",
        "equivLength": 493,
        "equivWidth": 170
    },
    {
        "bladeId": 61,
        "manufacturer": "Jantex",
        "model": "Gamma",
        "size": "Large",
        "bladeArea": 82000,
        "bladeLength": 510,
        "bladeWidth": 178,
        "tipToCoP": 232,
        "kickbackAngle": "",
        "equivLength": 485,
        "equivWidth": 169
    },
    {
        "bladeId": 135,
        "manufacturer": "Jantex",
        "model": "Gamma",
        "size": 820,
        "bladeArea": 82000,
        "bladeLength": 510,
        "bladeWidth": 178,
        "tipToCoP": 234,
        "kickbackAngle": "",
        "equivLength": 485,
        "equivWidth": 169
    },
    {
        "bladeId": 136,
        "manufacturer": "Jantex",
        "model": "Gamma",
        "size": 810,
        "bladeArea": 81000,
        "bladeLength": 507,
        "bladeWidth": 176,
        "tipToCoP": 232,
        "kickbackAngle": "",
        "equivLength": 483,
        "equivWidth": 168
    },
    {
        "bladeId": 62,
        "manufacturer": "Jantex",
        "model": "Gamma",
        "size": "Large Minus",
        "bladeArea": 80000,
        "bladeLength": 505,
        "bladeWidth": 176,
        "tipToCoP": 229,
        "kickbackAngle": "",
        "equivLength": 479,
        "equivWidth": 167
    },
    {
        "bladeId": 137,
        "manufacturer": "Jantex",
        "model": "Gamma",
        "size": 800,
        "bladeArea": 80000,
        "bladeLength": 505,
        "bladeWidth": 176,
        "tipToCoP": 231,
        "kickbackAngle": "",
        "equivLength": 479,
        "equivWidth": 167
    },
    {
        "bladeId": 132,
        "manufacturer": "Jantex",
        "model": "Gamma",
        "size": 790,
        "bladeArea": 79000,
        "bladeLength": 503,
        "bladeWidth": 168,
        "tipToCoP": 228,
        "kickbackAngle": "",
        "equivLength": 486,
        "equivWidth": 162
    },
    {
        "bladeId": 138,
        "manufacturer": "Jantex",
        "model": "Gamma",
        "size": 790,
        "bladeArea": 79000,
        "bladeLength": 503,
        "bladeWidth": 168,
        "tipToCoP": 230,
        "kickbackAngle": "",
        "equivLength": 486,
        "equivWidth": 162
    },
    {
        "bladeId": 63,
        "manufacturer": "Jantex",
        "model": "Gamma",
        "size": "Medium Plus",
        "bladeArea": 78000,
        "bladeLength": 500,
        "bladeWidth": 166,
        "tipToCoP": 227,
        "kickbackAngle": "",
        "equivLength": 485,
        "equivWidth": 161
    },
    {
        "bladeId": 139,
        "manufacturer": "Jantex",
        "model": "Gamma",
        "size": 780,
        "bladeArea": 78000,
        "bladeLength": 500,
        "bladeWidth": 166,
        "tipToCoP": 229,
        "kickbackAngle": "",
        "equivLength": 485,
        "equivWidth": 161
    },
    {
        "bladeId": 140,
        "manufacturer": "Jantex",
        "model": "Gamma",
        "size": 760,
        "bladeArea": 76000,
        "bladeLength": 495,
        "bladeWidth": 166,
        "tipToCoP": 227,
        "kickbackAngle": "",
        "equivLength": 476,
        "equivWidth": 160
    },
    {
        "bladeId": 64,
        "manufacturer": "Jantex",
        "model": "Gamma",
        "size": "Medium",
        "bladeArea": 74000,
        "bladeLength": 490,
        "bladeWidth": 164,
        "tipToCoP": 222,
        "kickbackAngle": "",
        "equivLength": 470,
        "equivWidth": 157
    },
    {
        "bladeId": 127,
        "manufacturer": "Jantex",
        "model": "Gamma",
        "size": "Medium Minus 740",
        "bladeArea": 74000,
        "bladeLength": 490,
        "bladeWidth": 166,
        "tipToCoP": 222,
        "kickbackAngle": "",
        "equivLength": 467,
        "equivWidth": 158
    },
    {
        "bladeId": 141,
        "manufacturer": "Jantex",
        "model": "Gamma",
        "size": 740,
        "bladeArea": 74000,
        "bladeLength": 490,
        "bladeWidth": 166,
        "tipToCoP": 224,
        "kickbackAngle": "",
        "equivLength": 467,
        "equivWidth": 158
    },
    {
        "bladeId": 142,
        "manufacturer": "Jantex",
        "model": "Gamma",
        "size": 730,
        "bladeArea": 73000,
        "bladeLength": 488,
        "bladeWidth": 160,
        "tipToCoP": 224,
        "kickbackAngle": "",
        "equivLength": 472,
        "equivWidth": 155
    },
    {
        "bladeId": 128,
        "manufacturer": "Jantex",
        "model": "Gamma",
        "size": "Small Plus 720",
        "bladeArea": 72000,
        "bladeLength": 485,
        "bladeWidth": 158,
        "tipToCoP": 220,
        "kickbackAngle": "",
        "equivLength": 470,
        "equivWidth": 153
    },
    {
        "bladeId": 143,
        "manufacturer": "Jantex",
        "model": "Gamma",
        "size": 720,
        "bladeArea": 72000,
        "bladeLength": 485,
        "bladeWidth": 158,
        "tipToCoP": 222,
        "kickbackAngle": "",
        "equivLength": 470,
        "equivWidth": 153
    },
    {
        "bladeId": 129,
        "manufacturer": "Jantex",
        "model": "Gamma",
        "size": "Small 700",
        "bladeArea": 70000,
        "bladeLength": 480,
        "bladeWidth": 158,
        "tipToCoP": 218,
        "kickbackAngle": "",
        "equivLength": 461,
        "equivWidth": 152
    },
    {
        "bladeId": 144,
        "manufacturer": "Jantex",
        "model": "Gamma",
        "size": 700,
        "bladeArea": 70000,
        "bladeLength": 480,
        "bladeWidth": 158,
        "tipToCoP": 220,
        "kickbackAngle": "",
        "equivLength": 461,
        "equivWidth": 152
    },
    {
        "bladeId": 130,
        "manufacturer": "Jantex",
        "model": "Gamma",
        "size": "Small Minus",
        "bladeArea": 68000,
        "bladeLength": 475,
        "bladeWidth": 158,
        "tipToCoP": 216,
        "kickbackAngle": "",
        "equivLength": 452,
        "equivWidth": 150
    },
    {
        "bladeId": 145,
        "manufacturer": "Jantex",
        "model": "Gamma",
        "size": 680,
        "bladeArea": 68000,
        "bladeLength": 475,
        "bladeWidth": 158,
        "tipToCoP": 218,
        "kickbackAngle": "",
        "equivLength": 452,
        "equivWidth": 150
    },
    {
        "bladeId": 146,
        "manufacturer": "Jantex",
        "model": "Gamma",
        "size": 660,
        "bladeArea": 66000,
        "bladeLength": 470,
        "bladeWidth": 155,
        "tipToCoP": 215,
        "kickbackAngle": "",
        "equivLength": 447,
        "equivWidth": 148
    },
    {
        "bladeId": 147,
        "manufacturer": "Jantex",
        "model": "Gamma",
        "size": 640,
        "bladeArea": 64000,
        "bladeLength": 465,
        "bladeWidth": 155,
        "tipToCoP": 213,
        "kickbackAngle": "",
        "equivLength": 438,
        "equivWidth": 146
    },
    {
        "bladeId": 65,
        "manufacturer": "Jantex",
        "model": "Gamma Rio",
        "size": "Large Plus",
        "bladeArea": 84000,
        "bladeLength": 515,
        "bladeWidth": 178,
        "tipToCoP": 236,
        "kickbackAngle": "",
        "equivLength": 493,
        "equivWidth": 170
    },
    {
        "bladeId": 66,
        "manufacturer": "Jantex",
        "model": "Gamma Rio",
        "size": "Large",
        "bladeArea": 82000,
        "bladeLength": 510,
        "bladeWidth": 178,
        "tipToCoP": 234,
        "kickbackAngle": "",
        "equivLength": 485,
        "equivWidth": 169
    },
    {
        "bladeId": 67,
        "manufacturer": "Jantex",
        "model": "Gamma Rio",
        "size": "Large Minus",
        "bladeArea": 80000,
        "bladeLength": 505,
        "bladeWidth": 178,
        "tipToCoP": 231,
        "kickbackAngle": "",
        "equivLength": 476,
        "equivWidth": 168
    },
    {
        "bladeId": 68,
        "manufacturer": "Jantex",
        "model": "Gamma Rio",
        "size": "Medium Plus",
        "bladeArea": 78000,
        "bladeLength": 500,
        "bladeWidth": 166,
        "tipToCoP": 229,
        "kickbackAngle": "",
        "equivLength": 485,
        "equivWidth": 161
    },
    {
        "bladeId": 69,
        "manufacturer": "Jantex",
        "model": "Gamma Rio",
        "size": "Medium",
        "bladeArea": 76000,
        "bladeLength": 495,
        "bladeWidth": 166,
        "tipToCoP": 227,
        "kickbackAngle": "",
        "equivLength": 476,
        "equivWidth": 160
    },
    {
        "bladeId": 70,
        "manufacturer": "Jantex",
        "model": "Gamma Rio",
        "size": "Medium Minus",
        "bladeArea": 74000,
        "bladeLength": 490,
        "bladeWidth": 166,
        "tipToCoP": 224,
        "kickbackAngle": "",
        "equivLength": 467,
        "equivWidth": 158
    },
    {
        "bladeId": 71,
        "manufacturer": "Jantex",
        "model": "Gamma Rio",
        "size": "Small Plus",
        "bladeArea": 72000,
        "bladeLength": 485,
        "bladeWidth": 158,
        "tipToCoP": 222,
        "kickbackAngle": "",
        "equivLength": 470,
        "equivWidth": 153
    },
    {
        "bladeId": 72,
        "manufacturer": "Jantex",
        "model": "Gamma Rio",
        "size": "Small",
        "bladeArea": 70000,
        "bladeLength": 480,
        "bladeWidth": 158,
        "tipToCoP": 220,
        "kickbackAngle": "",
        "equivLength": 461,
        "equivWidth": 152
    },
    {
        "bladeId": 108,
        "manufacturer": "Kialoa",
        "model": "Ekahi",
        "size": "",
        "bladeArea": 75400,
        "bladeLength": 460,
        "bladeWidth": 238,
        "tipToCoP": 177,
        "kickbackAngle": 11.5,
        "equivLength": 382,
        "equivWidth": 198
    },
    {
        "bladeId": 73,
        "manufacturer": "Kickthewaves",
        "model": "ZBsport Spear",
        "size": "Large",
        "bladeArea": 84000,
        "bladeLength": 520,
        "bladeWidth": 180,
        "tipToCoP": "",
        "kickbackAngle": "",
        "equivLength": 493,
        "equivWidth": 171
    },
    {
        "bladeId": 95,
        "manufacturer": "Kickthewaves",
        "model": "ZBsport Spear",
        "size": "Medium ",
        "bladeArea": "",
        "bladeLength": 580,
        "bladeWidth": 170,
        "tipToCoP": "",
        "kickbackAngle": "",
        "equivLength": "",
        "equivWidth": ""
    },
    {
        "bladeId": 96,
        "manufacturer": "Kickthewaves",
        "model": "ZBsport Spear",
        "size": "Small",
        "bladeArea": "",
        "bladeLength": 575,
        "bladeWidth": 167,
        "tipToCoP": "",
        "kickbackAngle": "",
        "equivLength": "",
        "equivWidth": ""
    },
    {
        "bladeId": 74,
        "manufacturer": "Legend",
        "model": "Fusion",
        "size": "Medium",
        "bladeArea": 73500,
        "bladeLength": 495,
        "bladeWidth": 163,
        "tipToCoP": "",
        "kickbackAngle": "",
        "equivLength": 472,
        "equivWidth": 156
    },
    {
        "bladeId": 93,
        "manufacturer": "Meek ",
        "model": "G-series",
        "size": "Medium",
        "bladeArea": 75300,
        "bladeLength": 500,
        "bladeWidth": 157,
        "tipToCoP": "",
        "kickbackAngle": "",
        "equivLength": 490,
        "equivWidth": 154
    },
    {
        "bladeId": 92,
        "manufacturer": "Meek ",
        "model": "G-series",
        "size": "Small",
        "bladeArea": 70800,
        "bladeLength": 474,
        "bladeWidth": 157,
        "tipToCoP": "",
        "kickbackAngle": "",
        "equivLength": 462,
        "equivWidth": 153
    },
    {
        "bladeId": 75,
        "manufacturer": "Orka",
        "model": "Bmax",
        "size": "Bmax",
        "bladeArea": 76000,
        "bladeLength": 500,
        "bladeWidth": 165,
        "tipToCoP": 235,
        "kickbackAngle": "",
        "equivLength": 480,
        "equivWidth": 158
    },
    {
        "bladeId": 76,
        "manufacturer": "Orka",
        "model": "Bmin",
        "size": "Bmin",
        "bladeArea": 73000,
        "bladeLength": 495,
        "bladeWidth": 160,
        "tipToCoP": 230,
        "kickbackAngle": "",
        "equivLength": 475,
        "equivWidth": 154
    },
    {
        "bladeId": 78,
        "manufacturer": "Orka",
        "model": "Flex",
        "size": "Flex",
        "bladeArea": 70500,
        "bladeLength": 490,
        "bladeWidth": 155,
        "tipToCoP": 228,
        "kickbackAngle": "",
        "equivLength": 472,
        "equivWidth": 149
    },
    {
        "bladeId": 121,
        "manufacturer": "Orka",
        "model": "Flex Pro",
        "size": "Regular",
        "bladeArea": 73000,
        "bladeLength": 500,
        "bladeWidth": 160,
        "tipToCoP": 233,
        "kickbackAngle": "",
        "equivLength": 478,
        "equivWidth": 153
    },
    {
        "bladeId": 174,
        "manufacturer": "Orka",
        "model": "Max Pro",
        "size": "Regular",
        "bladeArea": 76000,
        "bladeLength": 500,
        "bladeWidth": 168,
        "tipToCoP": 235,
        "kickbackAngle": "",
        "equivLength": 476,
        "equivWidth": 160
    },
    {
        "bladeId": 170,
        "manufacturer": "Orka",
        "model": "Min Pro",
        "size": "Regular",
        "bladeArea": 73000,
        "bladeLength": 495,
        "bladeWidth": 163,
        "tipToCoP": 230,
        "kickbackAngle": "",
        "equivLength": 471,
        "equivWidth": 155
    },
    {
        "bladeId": 171,
        "manufacturer": "Orka",
        "model": "Minni Pro",
        "size": "Regular",
        "bladeArea": 67000,
        "bladeLength": 470,
        "bladeWidth": 150,
        "tipToCoP": 218,
        "kickbackAngle": "",
        "equivLength": 458,
        "equivWidth": 146
    },
    {
        "bladeId": 172,
        "manufacturer": "Orka",
        "model": "Minni Pro",
        "size": "Small",
        "bladeArea": 64000,
        "bladeLength": 470,
        "bladeWidth": 145,
        "tipToCoP": 218,
        "kickbackAngle": "",
        "equivLength": 455,
        "equivWidth": 141
    },
    {
        "bladeId": 77,
        "manufacturer": "Orka",
        "model": "Super Flex",
        "size": "Super Flex Pro M",
        "bladeArea": 65000,
        "bladeLength": 500,
        "bladeWidth": 165,
        "tipToCoP": 233,
        "kickbackAngle": "",
        "equivLength": 444,
        "equivWidth": 146
    },
    {
        "bladeId": 122,
        "manufacturer": "Orka",
        "model": "Super Flex Pro",
        "size": "Large",
        "bladeArea": 77000,
        "bladeLength": 500,
        "bladeWidth": 170,
        "tipToCoP": 233,
        "kickbackAngle": "",
        "equivLength": 476,
        "equivWidth": 162
    },
    {
        "bladeId": 173,
        "manufacturer": "Orka",
        "model": "Super Flex Pro",
        "size": "Medium",
        "bladeArea": 75500,
        "bladeLength": 500,
        "bladeWidth": 165,
        "tipToCoP": 233,
        "kickbackAngle": "",
        "equivLength": 478,
        "equivWidth": 158
    },
    {
        "bladeId": 107,
        "manufacturer": "Quickblade",
        "model": "Kanaha",
        "size": 110,
        "bladeArea": 66200,
        "bladeLength": 410,
        "bladeWidth": 229,
        "tipToCoP": 160,
        "kickbackAngle": 10.5,
        "equivLength": 344,
        "equivWidth": 192
    },
    {
        "bladeId": 94,
        "manufacturer": "QuickBlade",
        "model": "V-drive",
        "size": "Medium 91",
        "bladeArea": 58700,
        "bladeLength": 445,
        "bladeWidth": 211,
        "tipToCoP": "",
        "kickbackAngle": "",
        "equivLength": 352,
        "equivWidth": 167
    },
    {
        "bladeId": 79,
        "manufacturer": "Raab",
        "model": "X16-L",
        "size": "X16-L",
        "bladeArea": 59200,
        "bladeLength": 487,
        "bladeWidth": 218,
        "tipToCoP": 207,
        "kickbackAngle": "",
        "equivLength": 364,
        "equivWidth": 163
    },
    {
        "bladeId": 103,
        "manufacturer": "Raab",
        "model": "X16-M",
        "size": "X16-M",
        "bladeArea": 57000,
        "bladeLength": 480,
        "bladeWidth": 210,
        "tipToCoP": 204,
        "kickbackAngle": "",
        "equivLength": 361,
        "equivWidth": 158
    },
    {
        "bladeId": 99,
        "manufacturer": "Raab",
        "model": "X16-S",
        "size": "X16-S",
        "bladeArea": 56000,
        "bladeLength": 480,
        "bladeWidth": 206,
        "tipToCoP": 204,
        "kickbackAngle": "",
        "equivLength": 361,
        "equivWidth": 155
    },
    {
        "bladeId": 120,
        "manufacturer": "Tai",
        "model": "Broadbill",
        "size": 9.5,
        "bladeArea": 82000,
        "bladeLength": 470,
        "bladeWidth": 241,
        "tipToCoP": 201,
        "kickbackAngle": 12,
        "equivLength": 400,
        "equivWidth": 205
    },
    {
        "bladeId": 179,
        "manufacturer": "Tai",
        "model": "Broadbill",
        "size": 9.25,
        "bladeArea": 79400,
        "bladeLength": 470,
        "bladeWidth": 235,
        "tipToCoP": 201,
        "kickbackAngle": 12,
        "equivLength": 398,
        "equivWidth": 199
    },
    {
        "bladeId": 178,
        "manufacturer": "Tai",
        "model": "Broadbill",
        "size": 9,
        "bladeArea": 77500,
        "bladeLength": 470,
        "bladeWidth": 230,
        "tipToCoP": 201,
        "kickbackAngle": 12,
        "equivLength": 398,
        "equivWidth": 195
    },
    {
        "bladeId": 133,
        "manufacturer": "Tai",
        "model": "Hiku",
        "size": 9.5,
        "bladeArea": 80500,
        "bladeLength": 470,
        "bladeWidth": 240,
        "tipToCoP": 202,
        "kickbackAngle": 15,
        "equivLength": 397,
        "equivWidth": 203
    },
    {
        "bladeId": 177,
        "manufacturer": "Tai",
        "model": "Hiku",
        "size": 9.25,
        "bladeArea": 79000,
        "bladeLength": 470,
        "bladeWidth": 235,
        "tipToCoP": 202,
        "kickbackAngle": 15,
        "equivLength": 397,
        "equivWidth": 199
    },
    {
        "bladeId": 176,
        "manufacturer": "Tai",
        "model": "Hiku",
        "size": 9,
        "bladeArea": 77500,
        "bladeLength": 470,
        "bladeWidth": 230,
        "tipToCoP": 202,
        "kickbackAngle": 15,
        "equivLength": 398,
        "equivWidth": 195
    },
    {
        "bladeId": 183,
        "manufacturer": "Tai",
        "model": "Kingfish",
        "size": 10,
        "bladeArea": 87700,
        "bladeLength": 488,
        "bladeWidth": 254,
        "tipToCoP": 213,
        "kickbackAngle": 9.5,
        "equivLength": 410,
        "equivWidth": 214
    },
    {
        "bladeId": 182,
        "manufacturer": "Tai",
        "model": "Kingfish",
        "size": 9.5,
        "bladeArea": 81500,
        "bladeLength": 478,
        "bladeWidth": 240,
        "tipToCoP": 208,
        "kickbackAngle": 9.5,
        "equivLength": 403,
        "equivWidth": 202
    },
    {
        "bladeId": 181,
        "manufacturer": "Tai",
        "model": "Kingfish",
        "size": 9.25,
        "bladeArea": 78600,
        "bladeLength": 472,
        "bladeWidth": 235,
        "tipToCoP": 206,
        "kickbackAngle": 9.5,
        "equivLength": 397,
        "equivWidth": 198
    },
    {
        "bladeId": 180,
        "manufacturer": "Tai",
        "model": "Kingfish",
        "size": 9,
        "bladeArea": 75700,
        "bladeLength": 468,
        "bladeWidth": 230,
        "tipToCoP": 204,
        "kickbackAngle": 9.5,
        "equivLength": 392,
        "equivWidth": 193
    },
    {
        "bladeId": 80,
        "manufacturer": "Turbo",
        "model": "Turbo 1 Classic",
        "size": "Men Large",
        "bladeArea": 82000,
        "bladeLength": 520,
        "bladeWidth": 170,
        "tipToCoP": 245,
        "kickbackAngle": "",
        "equivLength": 501,
        "equivWidth": 164
    },
    {
        "bladeId": 81,
        "manufacturer": "Turbo",
        "model": "Turbo 1 Classic",
        "size": "Men Medium",
        "bladeArea": 77000,
        "bladeLength": 500,
        "bladeWidth": 165,
        "tipToCoP": 236,
        "kickbackAngle": "",
        "equivLength": 483,
        "equivWidth": 159
    },
    {
        "bladeId": 82,
        "manufacturer": "Turbo",
        "model": "Turbo 1 Classic",
        "size": "Women Large",
        "bladeArea": 73000,
        "bladeLength": 490,
        "bladeWidth": 160,
        "tipToCoP": 231,
        "kickbackAngle": "",
        "equivLength": 473,
        "equivWidth": 154
    },
    {
        "bladeId": 175,
        "manufacturer": "Turbo",
        "model": "Turbo 1 Classic",
        "size": "Woman Medium",
        "bladeArea": 69000,
        "bladeLength": 480,
        "bladeWidth": 150,
        "tipToCoP": 227,
        "kickbackAngle": "",
        "equivLength": 470,
        "equivWidth": 147
    },
    {
        "bladeId": 83,
        "manufacturer": "Turbo",
        "model": "Turbo 2",
        "size": "Large",
        "bladeArea": 82000,
        "bladeLength": 490,
        "bladeWidth": 178,
        "tipToCoP": 222,
        "kickbackAngle": "",
        "equivLength": 475,
        "equivWidth": 173
    },
    {
        "bladeId": 84,
        "manufacturer": "Turbo",
        "model": "Turbo 2",
        "size": "Medium",
        "bladeArea": 77000,
        "bladeLength": 470,
        "bladeWidth": 177,
        "tipToCoP": 213,
        "kickbackAngle": "",
        "equivLength": 452,
        "equivWidth": 170
    },
    {
        "bladeId": 85,
        "manufacturer": "Turbo",
        "model": "Turbo 2",
        "size": "Women/Junior",
        "bladeArea": 70000,
        "bladeLength": 460,
        "bladeWidth": 160,
        "tipToCoP": 208,
        "kickbackAngle": "",
        "equivLength": 449,
        "equivWidth": 156
    },
    {
        "bladeId": 86,
        "manufacturer": "Turbo",
        "model": "Turbo 3",
        "size": "Large ",
        "bladeArea": 84000,
        "bladeLength": 530,
        "bladeWidth": 170,
        "tipToCoP": 250,
        "kickbackAngle": "",
        "equivLength": 512,
        "equivWidth": 164
    },
    {
        "bladeId": 87,
        "manufacturer": "Turbo",
        "model": "Turbo 3",
        "size": "Medium ",
        "bladeArea": 78000,
        "bladeLength": 500,
        "bladeWidth": 165,
        "tipToCoP": 236,
        "kickbackAngle": "",
        "equivLength": 486,
        "equivWidth": 160
    },
    {
        "bladeId": 88,
        "manufacturer": "Turbo",
        "model": "Turbo 3",
        "size": "Women",
        "bladeArea": 70000,
        "bladeLength": 470,
        "bladeWidth": 160,
        "tipToCoP": 222,
        "kickbackAngle": "",
        "equivLength": 453,
        "equivWidth": 154
    },
    {
        "bladeId": 102,
        "manufacturer": "Vajda",
        "model": "Martikan",
        "size": "",
        "bladeArea": 83000,
        "bladeLength": 514,
        "bladeWidth": 195,
        "tipToCoP": 221,
        "kickbackAngle": 0,
        "equivLength": 468,
        "equivWidth": 177
    }
    ];

export default bladeList;